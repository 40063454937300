.work {
    display: flex;
}

.job-tabs {
    position: relative;
    z-index: 3;
    width: max-content;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.job-panels {
    position: relative;
    width: 100%;
    margin-left: 20px;
    z-index: 6;
}

@media (min-width: 700px) {
    .work {
        min-height: 340px;
    }
}

@media (max-width: 600px) {
    .work {
        display: block;
    }

    .job-tabs {
        display: flex;
        overflow-x: auto;
        width: calc(100% + 100px);
        padding-left: 0px;
        margin-left: 10px;
        margin-bottom: 30px;
    }

    .job-panels {
        margin-left: 0px;
    }
}

@media (max-width: 480px) {
    .job-tabs {
        width: calc(100% + 50px);
        padding-left: 25px;
        margin-left: -25px;
    }
}