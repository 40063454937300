.hero_container {
    counter-reset: section 0;
}

main {
    margin: 0px auto;
    max-width: 1600px;
    min-height: 100vh;
    padding: 200px 150px;
}

main.fillHeight {
    padding: 0px 150px;
}

section {
    margin: 0px auto;
    padding: 100px 0px;
    max-width: 1000px;
}

.hero_StyledHeroSection {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    padding: 0px;
}

.hero_StyledHeroSection h1 {
    margin: 0px 0px 30px 4px;
    color: var(--imperial-red);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-md), 5vw, var(--fz-lg));
    font-weight: 400;
}

.hero_StyledHeroSection h2 {
    margin: 0px 0px 10px;
    font-weight: 600;
    color: var(--cultured);
    line-height: 1.1;
  }

.hero_StyledHeroSection h3 {
    margin: 10px 0px;
    color: var(--manatee);
    line-height: 0.9;
}

.hero_StyledHeroSection p {
    margin: 20px 0px 0px;
    max-width: 615px;
}

.big-heading {
    margin: 0px;
    font-size: clamp(28px, 8vw, 55px);
}

.biggest-heading {
    margin: 0px;
    font-size: clamp(40px, 8vw, 80px);
}

.hero_StyledAboutSection {
    max-width: 900px;
}

.hero_StyledAboutSection .section_container {
    display: grid;
    grid-template-rows: 3fr 2fr;
    gap: 50px;
}

.hero_StyledAboutSection li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--amranth-red);
}

.hero_StyledWorkSection {
    max-width: 800px;
}

.hero_StyledWorkSection li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--amranth-red);
}

.numbered-heading {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin: 10px 0px 40px;
    width: 100%;
    font-size: clamp(26px, 5vw, var(--fz-heading));
    white-space: nowrap;
}

.numbered-heading::before {
    position: relative;
    bottom: 4px;
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: 10px;
    color: var(--amranth-red);
    font-size: clamp(var(--fz-md), 3vw, var(--fz-xl));
    font-weight: 400;
}

.numbered-heading::after {
    content: "";
    display: block;
    position: relative;
    top: 0px;
    width: 300px;
    height: 1px;
    margin-left: 20px;
    background-color: var(--space-cadet-shadow);
}

.project-list {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.project-list .project-card:not(:last-of-type) {
    margin-bottom: 180px;
}

@media (max-width: 1080px) {
    main.fillHeight {
        padding: 0px 100px;
    }

    .numbered-heading::after {
        width: 200px;
    }
}

@media (max-width: 768px) {
    main.fillHeight {
        padding: 0px 50px;
    }

    section {
        padding: 80px 0px;
    }

    .numbered-heading::after {
        width: 100%;
    }

    .hero_StyledAboutSection .section_container {
        display: block;
    }

    .project-list-item:not(:last-of-type) {
        margin-bottom: 70px;
    }
}

@media (max-width: 600px) {
    .numbered-heading::after {
        margin-left: 10px;
    }
}

@media (max-width: 480px) {
    main.fillHeight {
        padding: 0px 25px;
    }

    .hero_StyledHeroSection h1 {
        margin: 0px 0px 20px 2px;
    }

    section {
        padding: 60px 0px;
    }

    .numbered-heading::before {
        margin-bottom: -3px;
        margin-right: 5px;
    }
    
    .project-list-item:not(:last-of-type) {
        margin-bottom: 30px;
    }
}

@media (max-width: 480px) and (min-height: 700px) {
    .hero_StyledHeroSection {
        padding-bottom: 0px;
    }
}