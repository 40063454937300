.job-button {
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--transition);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: var(--tab-height);
    border-left: 2px solid var(--light-space-cadet);
    background-color: transparent;
    color: var(--manatee);
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    text-align: left;
    white-space: nowrap;
    border: 0px;
    border-radius: 0px;
}

.job-button:hover {
    color: var(--amranth-red);
    background-color: var(--lightest-space-cadet);
}

.active {
    color: var(--amranth-red);
    background-color: var(--light-space-cadet);
}

@media (max-width: 600px) {
    .job-button {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        min-width: 120px;
        padding: 0px 15px;
        border-left: 0px;
        border-bottom: 2px solid var(--lightest-space-cadet);
        text-align: center;
    }
}