.thumbnail {
    display: flex;
    vertical-align: middle;
    padding: 5px 5px;
    transition: var(--transition);
}

.thumbnail img {
    width: 100%;
    height: 100%;
}

.thumbnail:hover {
    transform: var(--transform);
}

.content a {
    text-decoration: none;
}

.proj-header {
    display: flex;
    align-items: center;
}

.title {
    font-size: clamp(24px, 5vw, 28px);
    color: var(--amranth-red);
    transition: var(--transition);
}

.title a {
    color: inherit;
}

.title a:hover {
    color: var(--imperial-red);
}

.tools {
    color: var(--cultured);
    padding-left: 15px;
    font-style: italic;
}

.project-card {
    min-height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 10px;
}

.project-card .content {
    position: relative;
    grid-area: 1 / 1 / -1 / 7;
}

.project-card:nth-of-type(2n+1) .content {
    position: relative;
    grid-area: 1 / 6 / -1 / 12;
}

.project-card .thumbnail {
    position: relative;
    grid-area: 1 / 7 / -1 / 12;
}

.project-card:nth-of-type(2n+1) .thumbnail {
    position: relative;
    grid-area: 1 / 1 / -1 / 6;
}

.project-card ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.project-card ul li {
    padding: 10px 0px;
    list-style-position: outside;
    position: relative;
    margin-left: 1em;
}

.project-card li::before {
    content: "▹";
    position: absolute;
    top: 10px;
    left: -20px;
    color: var(--amranth-red);
}

.project-links {
    height: 100px;
}

.project-links span {
    position: absolute;
    bottom: 5px;
}

.project-links a {
    padding: 0px 8px;
    color: var(--cultured);
    transition: var(--transition);
}

.project-links a:hover {
    color: var(--imperial-red);
}

@media (max-width: 768px) {
    .project-card {
        display: block;
    }

    .content {
        text-align: left;
        padding-right: 10px;
    }

    .project-links {
        display: flex;
        align-items: center;
    }

    .project-links span {
        position: relative;
    }
}

@media (max-width: 480px) {
    .proj-header {
        display: block;
    }

    .tools {
        padding-left: 0px;
    }
}