p > a {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--transition);
    color: var(--amranth-red);
}

p > a:hover {
    color: var(--imperial-red);
}