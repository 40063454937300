.job-panel {
    width: 100%;
    height: auto;
    padding: 0px 5px;
    animation: fadeEffect var(--transition);
}

.job-panel h3 {
    margin-bottom: 10px;
    font-size: var(--fz-xxl);
    font-weight: 500;
    line-height: 1.3;
}

.job-panel h3 .company {
    color: var(--amranth-red);
}

.job-panel ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    font-size: var(--fz-lg);
}

.job-panel ul li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
}

.range {
    margin-bottom: 25px !important;
    color: var(--manatee);
    font-family: var(--font-mono);
    font-size: var(--fz-xs);
}

.inline-link {
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: var(--transition);
    color: var(--amranth-red);
}

.inline-link:hover {
    color: var(--imperial-red);
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
}