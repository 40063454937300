:root {
  --dark-space-cadet: #151620;
  --space-cadet: #191A25;
  --light-space-cadet: #212331;
  --lightest-space-cadet: #282A3B;
  --space-cadet-shadow: rgba(70, 72, 110, 0.7);
  --manatee: #96A4BA;
  --cultured: #EDF2F4;
  --imperial-red: #EF233C;
  --amranth-red: #E4052A;
  --font-sans: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --nav-height: 100px;
  --nav-scroll-height: 70px;
  --tab-height: 42px;
  --tab-width: 120px;
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  --hamburger-width: 30px;
  --ham-before: top 0.1s ease-in 0.2s, opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.2s, transform 0.18s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ham-after-active: bottom 0.1s, ease-out, transform 0.22s, cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  --transform: scale(1.015);
  --styled-padding: 200px;
}

body {
  margin: 0px;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  background-color: var(--space-cadet) !important;
  color: var(--manatee) !important;
  font-family: var(--font-sans);
  font-size: var(--fz-xl);
  line-height: 1.3;
}

body.blur {
  overflow: hidden;
}

body.blur main > * {
  filter: blur(5px) brightness(0.7);
  transition: var(--transition);
  pointer-events: none;
  user-select: none;
}

h1 {
  margin: 0px 0px 10px;
  font-weight: 600;
  color: var(--cultured);
  line-height: 1.1;
}

h2 {
  margin: 0px 0px 10px;
  font-weight: 600;
  color: var(--cultured);
  line-height: 1.1;
}

h3 {
  margin: 0px 0px 10px;
  font-weight: 600;
  color: var(--cultured);
  line-height: 1.1;
}

p {
  margin: 0px 0px 15px;
}

p:last-child, p:last-of-type {
  margin: 0px;
}

::selection {
  background-color: var(--lightest-space-cadet);
  color: var(--cultured);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 480px) {
  body {
    font-size: var(--fz-lg);
  }
}
